export default {
  methods: {
    getProcessLogData(params) {
      return this.$api.get('robot_logs', params)
        .then(({ data }) => {
          return data
        })
        .catch(() => null)
    }
  }
}
