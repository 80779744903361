<template>
  <div class="rcc-table-list">
    <div class="rcc-table-list__table-wrapper">
      <rcc-table
        :page.sync="page"
        :pageCount="pagesCount"
        :itemsPerPage="rowsOnTable"
        :headers="headers"
        :rows="rows"
        :is-fixed-header="true"
        :isLoading="isDataLoading"
        @filters-changed="handleFiltersChanged"
      />
    </div>
  </div>
</template>

<script>
import Page from '@/mixins/page'
import TableList from '@/mixins/table-list'
import headerData from './headers/process-log-header'
import ProcessLogApi from '@/api/process-log'

import RccTable from 'Components/table/rcc-table'

export default {
  components: { RccTable },

  mixins: [Page, TableList, ProcessLogApi],

  computed: {
    headers() {
      return headerData
    }
  },

  methods: {
    loadData(filters) {
      this.isDataLoading = true

      this.getProcessLogData({ page: this.page, per_page: this.rowsOnTable, ...this.modifyFilters(filters) })
        .then(data => {
          if (data === null) {
            this.pagesCount = 1
            this.rows = []
            return
          }

          this.pagesCount = data.pages
          this.rows = data.items.map(item => ({
            ...item,
            start: item.start && this.$moment(item.start).format('DD.MM.YYYY HH:mm'),
            stop: item.stop && this.$moment(item.stop).format('DD.MM.YYYY HH:mm'),
            isAttention: item.error
          }))
        })
        .finally(() => { this.isDataLoading = false })
    },

    modifyFilters(filters) {
      if (filters.start) {
        return filters
      }

      const displayDays = 30
      const end = this.$moment().format('YYYY-MM-DD')
      const start = this.$moment(end).subtract(displayDays, 'days').format('YYYY-MM-DD')

      return { ...filters, start: [start, end] }
    }
  }
}
</script>
