export default [
  {
    text: 'Начало',
    value: 'start',
    width: '110px',
    customFilter: {
      type: 'datepicker'
    },
    colModifiers:[
      {
        type: 'width',
        value: '110px'
      }
    ]
  },
  {
    text: 'Окончание',
    value: 'stop',
    width: '110px',
    customFilter: {
      type: 'datepicker'
    },
    colModifiers:[
      {
        type: 'width',
        value: '110px'
      }
    ]
  },
  {
    text: 'Процесс',
    value: 'name',
    width: '200px',
    customFilter: {
      type: 'search'
    },
    colModifiers:[
      {
        type: 'max-width',
        value: '300px'
      }
    ]
  },
  {
    text: 'Сообщение',
    value: 'result',
    colModifiers:[
      {
        type: 'max-width',
        value: '100%'
      },
      {
        type: 'pre-wrap'
      }
    ]
  }
]
